var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"py-2 position-relative",staticStyle:{"height":"100%"},attrs:{"tile":""}},[_c('v-system-bar',{attrs:{"color":_vm.stateColor,"height":5,"absolute":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('CardTitle',{attrs:{"title":_vm.cardTitle,"icon":"pi-flow","icon-color":_vm.stateColor,"loading":_vm.loading > 0}},[_c('div',_vm._g({attrs:{"slot":"action"},slot:"action"},on),[_c('v-select',{staticClass:"time-interval-picker",attrs:{"items":_vm.shortDateFilters,"dense":"","solo":"","item-text":"name","item-value":"value","hide-details":"","flat":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"utilGrayDark","x-small":""}},[_vm._v(" history ")])]},proxy:true}],null,true),model:{value:(_vm.selectedDateFilter),callback:function ($$v) {_vm.selectedDateFilter=$$v},expression:"selectedDateFilter"}})],1)])]}}])},[_c('span',[_vm._v(" Filter by when flows were last updated ")])]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{staticClass:"card-content"},[(_vm.loading > 0)?_c('v-slide-y-reverse-transition',{attrs:{"leave-absolute":"","group":""}},[_c('v-skeleton-loader',{key:"skeleton",attrs:{"type":"list-item-three-line"}})],1):(_vm.hasError)?_c('v-slide-y-reverse-transition',{attrs:{"leave-absolute":"","group":""}},[_c('v-list-item',{key:"error",attrs:{"color":"grey"}},[_c('v-list-item-avatar',{staticClass:"mr-0"},[_c('v-icon',{staticClass:"error--text"},[_vm._v(" error ")])],1),_c('v-list-item-content',{staticClass:"my-0 py-3"},[_c('div',{staticClass:"d-inline-block text-subtitle-1 font-weight-light",staticStyle:{"line-height":"1.25rem"}},[_vm._v(" Something went wrong while trying to fetch failed flow information. Please try refreshing your page. If this error persists, return to this page after a few minutes. ")])])],1)],1):(_vm.failureCount)?_c('v-slide-y-reverse-transition',{attrs:{"leave-absolute":"","group":""}},_vm._l((_vm.failures),function(failure){return _c('v-lazy',{key:failure.flow_id,attrs:{"options":{
            threshold: 0.75
          },"min-height":"40px","transition":"slide-y"}},[_c('v-list-item',{attrs:{"to":{
              name: 'flow',
              params: { id: failure.flow.flow_group_id }
            }}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{staticClass:"text-truncate d-inline-block",staticStyle:{"max-width":"95%"}},[_vm._v(" "+_vm._s(failure.flow.name)+" ")])]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.formatDateTime(failure.state_timestamp))+" ")])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("arrow_right")])],1)],1)],1)}),1):_c('v-slide-y-transition',{attrs:{"leave-absolute":"","group":""}},[_c('v-list-item',{key:"no-data",attrs:{"color":"grey"}},[_c('v-list-item-avatar',{staticClass:"mr-0"},[_c('v-icon',{staticClass:"green--text"},[_vm._v("check")])],1),_c('v-list-item-content',{staticClass:"my-0 py-0"},[_c('div',{staticClass:"text-subtitle-1 font-weight-light",staticStyle:{"line-height":"1.25rem"}},[_vm._v(" No reported failures in the last "+_vm._s(_vm.selectedDateFilter)+"... Everything looks good! ")])])],1)],1)],1),(_vm.failures && _vm.failures.length > 3)?_c('div',{staticClass:"pa-0 footer"}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }